define('apps/docs/_enums/PermissionTypesEnum',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * @class PermissionTypesEnum
	 * @implements {app.BaseEnum}
	 * @memberOf {app.docs}
	 *
	 * @param BaseEnum {Function} Augmentation of enum-type object
	 * @param gettext {Function} Translation helper service
	 * @param $log {Object} Logging service
	 * @return {Function} Enum instance
	 */
	function PermissionTypesEnum($log, BaseEnum, gettext) {

		$log = $log.getInstance('app.docs.PermissionTypesEnum');
		$log.debug('Initiated enum object');

		return new BaseEnum({
			EDIT: {
				label: gettext('Edit'),
				description: gettext('view and edit the document')
			},
			READ: {
				label: gettext('Read'),
				description: gettext('view the document')
			},
			SHARE_READ: {
				label: gettext('Share'),
				description: gettext('view and share the read-only document')
			},
			SHARE_EDIT: {
				label: gettext('All'),
				description: gettext('view, edit and share the document')
			}
		});
	}

	module.service('PermissionTypesEnum', PermissionTypesEnum);
});

