/**
 * @namespace app.docs.documents
 * @memberof app.docs
 */

define('apps/docs/documents/shared/module',['angular'], function (ng) {
	'use strict';

	/**
	 * Instantiate the module
	 * @constructor module
	 * @memberof app.docs
	 */
	var module = ng.module('app.docs.documents.shared', []);

	/**
	 * Stores additional module configuration
	 * @method run
	 * @memberof app.docs.module
	 */
	module.config(function ($stateProvider, gettext) {

		$stateProvider
			.state('app.docs.documents.shared', {
				url: '/docs/shared?token',
				views: {
					'content@app': {
						templateUrl: 'apps/docs/documents/shared/views/list.html'
					}
				},
				data: {
					title: gettext('Shared')
				}
			})

			.state('app.docs.documents.shared.edit', {
				url: '/shared/edit/:id/:version',
				params: {
					version: null
				},
				views: {
					'content@app': {
						templateUrl: 'apps/docs/documents/shared/views/view.html'
					}
				},
				data: {
					title: gettext('Edit')
				}
			})

			.state('app.docs.documents.shared.preview', {
				url: '/shared/preview/:id/:version',
				params: {
					version: null
				},
				views: {
					'content@app': {
						templateUrl: 'apps/docs/documents/shared/views/preview.html'
					}
				},
				data: {
					title: gettext('Preview')
				}
			});
	});

	return module;
});

