requirejs([
	'seed/require.conf',
	'apps/docs/require.conf'
], function () {
	'use strict';

	requirejs([
		'apps/_includes',
		'apps/module'
	], function () {
		angular
			.element(document)
			.ready(function () {
				angular.bootstrap(angular.element('html').addClass('ng-app'), ['app']);
			});
	});
});

define("container", function(){});

