define('apps/docs/_includes',[
	// Shared
	'./_models/_includes',
	'./_enums/_includes',
	'./_directives/_includes',

	// Modules
	'./documents/module',
	'./documents/_includes',

	'./templates/module',
	'./templates/_includes',

	// Templates
	'./__misc/_templates/module',

	// Translations
	'./__misc/_locale/translation'
], function () {
	'use strict';
});

