define('apps/docs/_models/Field/TextField',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Defines extend class of Field holding plain input field model initial properties
	 * @class TextField
	 * @extends {app.docs.Field}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Logging service
	 * @param restmod {Object} Object Relational Mapper interface
	 * @param gettextCatalog {Object} Translated text's catalog provider
	 * @return {*|Model} Model instance
	 */
	function TextField($log, restmod, gettextCatalog) {

		$log = $log.getInstance('app.docs.TextField');
		$log.debug('Created new instance');

		return restmod
			.model()
			.mix('Field', {
				$templateUrl: {
					init: 'apps/docs/_directives/docsField/fields/input.html'
				},
				$propertiesUrl: {
					init: 'apps/docs/_directives/docsFieldProperties/input-properties.html'
				},
				$inputType: {
					init: 'text'
				},

				label: {
					init: function () {
						return gettextCatalog.getString('Text');
					}
				}
			});
	}

	module.factory('TextField', TextField);
});

