define('apps/docs/_directives/docsFieldProperties/docsFieldProperties',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Shows modal with field properties based on what kind of field is clicked
	 * @class docsFieldProperties
	 * @memberOf app.docs.templates.documents
	 *
	 * @param $log {Object} Logging service
	 * @return {{restrict: string, controllerAs: string, controller: Function}}
	 */
	function docsFieldProperties($log) {

		$log = $log.getInstance('app.docs.docsFieldProperties');
		$log.debug('Initiated directive');

		return {
			restrict: 'E',
			templateUrl: 'apps/docs/_directives/docsFieldProperties/docs-field-properties.html',
			controllerAs: 'vm',
			controller: function ($scope) {
				var vm = this;

				vm.closeModal = closeModal;

				function closeModal() {
					$scope.$parent.modalInstance.dismiss();

					$log.debug('Closed field properties modal');
				}

				$log.debug('Initiated controller');
			}
		};
	}

	module.directive('docsFieldProperties', docsFieldProperties);
});

