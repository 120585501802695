define('apps/docs/_directives/docsShareModal/docsShareModal',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * @class docsShareModal
	 * @memberOf app.docs.templates.documents
	 *
	 * @param $log {Object} Logging service
	 * @param PermissionTypesEnum
	 * @param DocumentSharingAPI
	 * @param neoTableParams
	 * @return {{restrict: string, templateUrl: string, controllerAs: string, controller: Function}}
	 */
	function docsShareModal($log, PermissionTypesEnum, DocumentSharingAPI, neoTableParams) {

		$log = $log.getInstance('app.docs.docsShareModal');
		$log.debug('Initiated directive');

		return {
			restrict: 'E',
			templateUrl: 'apps/docs/_directives/docsShareModal/docs-share-modal.html',
			controllerAs: 'vm',
			controller: function ($scope) {
				var vm = this;

				// variables
				vm.sharePrivileges = PermissionTypesEnum;
				vm.documentSharing = DocumentSharingAPI.build({documentId: $scope.documentId});
				vm.shareFlag = $scope.shareFlag;
				vm.documentReceiverOptions = neoTableParams(DocumentSharingAPI, {
					getData: function ($defer, params) {
						var queryParams = {
							first: (params.url().page - 1) * params.url().count,
							pageSize: params.url().count,
							sort: params.sorting(),
							filter: params.filter()
						};

						return DocumentSharingAPI
							.getProvided(_.assign({}, queryParams, {documentId: $scope.documentId}))
							.then(function (models) {
								params.total(models.$metadata.total);
								$defer.resolve(models);
								return models;
							});
					}
				});

				// functions
				vm.closeModal = closeModal;
				vm.choosePrivilege = choosePrivilege;
				vm.shareToUser = shareToUser;
				vm.updatePrivilege = updatePrivilege;
				vm.deletePrivilege = deletePrivilege;

				/**
				 * choosing privilege to new sharing
				 * @param privilege
				 */
				function choosePrivilege(privilege) {
					vm.documentSharing.privilege.type = privilege;
				}


				/**
				 * share document to user
				 *
				 * Request payload:
				 *  documentId: ""
				 *  email: ""
				 *  privilege: {type: ""}
				 * }
				 */
				function shareToUser() {
					DocumentSharingAPI
						.share(vm.documentSharing)
						.then(function () {
							$scope.$parent.modalInstance.dismiss();
							$log.debug('Shared with userEmail' + vm.documentSharing.email);
						})
						.catch(DocumentSharingAPI.handleError);
					$log.debug('Leaving shareToUser function');

				}

				/**
				 * updates receiver privileges
				 * Request payload:
				 *  documentId: ""
				 *  id: ""
				 *  email: ""
				 *  privilege: {type: ""}
				 * }
				 * @param privilege
				 * @param shareData
				 */

				function updatePrivilege(privilege, shareData) {
					vm.changePrivilegeDocumentSharing = DocumentSharingAPI.build({
						documentId: $scope.documentId,
						id: shareData.id,
						email: shareData.receiver.userEmail
					});
					vm.changePrivilegeDocumentSharing.privilege.type = privilege;
					DocumentSharingAPI
						.updateProvided(vm.changePrivilegeDocumentSharing)
						.then( function () {
							$log.debug('Updated privileges for userEmail' + shareData.receiver.userEmail);
						});
					$log.debug('Leaving shareToUser function');

				}

				/**
				 * block receiver access to document
				 * @param shareId
				 */
				function deletePrivilege(shareId) {
					DocumentSharingAPI
						.deleteProvided(DocumentSharingAPI.build({documentId: $scope.documentId, id: shareId}))
						.then(function () {
							for (var i = vm.documentReceiverOptions.data.length - 1; i >= 0; i--) {
								if (vm.documentReceiverOptions.data[i].id === shareId) {
									//delete row from data
									vm.documentReceiverOptions.data.splice(i, 1);
								}
							}
							$log.debug('Deleted privileges for shareId' + shareId);
							//vm.documentReceiverOptions.reload();
						});
				}

				/**
				 * Closes share modal
				 */
				function closeModal() {
					$scope.$parent.modalInstance.dismiss();
					$log.debug('Closed share modal');
				}

				$log.debug('Initiated controller');
			}
		};
	}

	module.directive('docsShareModal', docsShareModal);
});

