define('seed/components/_includes',[
	'./activities/neoActivities',
	'./customer/neoCustomerSwitcher',
	'./language/neoLanguageSwitcher',
	'./messages/appMessages',
	'./preload/neoPreload',
	'./euLogotypes/neoEuLogotypes',

	'./breadcrumbs/neoStateBreadcrumbs',
	'./breadcrumbs/bigBreadcrumbs',

	'./navigation/neoNavigation',
	'./navigation/neoNavigationGroup',
	'./navigation/neoNavigationItem',
	'./pageTitle/neoPageTitle',

	'./moment/neoMomentTime',
	'./moment/neoMomentDate',
	'./moment/neoMomentDatetime',
	'./moment/momentDatetimeDayrange'
], function () {
	'use strict';
});

