define('seed/__misc/_templates/module',['angular'], function(angular) { /*jshint quotmark: false*/ "use strict"; return angular.module("seed.templateCache",[]).run(function ($templateCache, $log) {$log = $log.getInstance('seed.templateCache.module');  

  $templateCache.put('seed/auth/lock/forms/authLockForm.html',
    "<form class=neo-form><img ng-src={{vm.user.avatar}}><fieldset><h1 class=text-center>{{vm.user.getFullName()}} <small><i class=\"fa fa-lock text-muted\"></i> &nbsp;Locked</small></h1></fieldset><fieldset><div class=input-icon-right><i class=\"icon-append fa fa-lock\"></i> <input class=form-control type=password placeholder=\"{{'Password'|translate}}\"></div><p class=\"no-margin margin-top-5\">{{'Logged as someone else?'|translate}} <a ui-sref=auth.login>{{'Click here'|translate}}</a></p></fieldset><footer><button type=submit class=\"btn btn-primary\">{{'Unlock'|translate}}</button></footer></form>"
  );


  $templateCache.put('seed/auth/login/forms/login/authLoginForm.html',
    "<form class=neo-form ng-submit=vm.login() novalidate><img ng-src={{vm.user.avatar}}><fieldset><h1 class=text-center>{{'Welcome!'|translate}} <small>{{'Please log in'|translate}}</small></h1></fieldset><fieldset><section class=form-group ng-show=vm.formError><p class=\"alert alert-danger\" ng-model=formError><button type=button class=close data-dismiss=alert>&times;</button> {{'Wrong e-mail or password.'|translate}}</p></section><section class=form-group><div class=input-icon-right><i class=\"icon-append fa fa-user\"></i> <input type=email class=form-control placeholder=\"{{'Email'|translate}}\" ng-model=vm.user.login></div></section><section class=form-group><div class=input-icon-right><i class=\"icon-append fa fa-lock\"></i> <input type=password class=form-control placeholder=\"{{'Password'|translate}}\" ng-model=vm.user.password></div><div class=note><a ui-sref=auth.forgotPassword>{{'Forgot password?'|translate}}</a></div></section><section ng-if=vm.appConf.generalSettings.showEuLogotypes style=\"height: 125px\"><neo-eu-logotypes></neo-eu-logotypes></section></fieldset><footer><button type=submit class=\"btn btn-primary\">{{'Log in'|translate}}</button><div class=btn-group ng-show=vm.predefinedLogins.length><button class=\"btn btn-default dropdown-toggle\" data-toggle=dropdown>{{'Login as'|translate}} <span class=caret></span></button><ul class=dropdown-menu><li ng-repeat=\"user in vm.predefinedLogins\"><a href ng-click=vm.loginAs(user)>{{ user.login }}</a></li></ul></div></footer></form>"
  );


  $templateCache.put('seed/auth/login/forms/profileSelect/authProfileSelectForm.html',
    "<form class=neo-form ng-submit=vm.login() novalidate><img ng-src={{vm.user.avatar}}><fieldset><h1 class=text-center>{{'Select profile'|translate}} <small>{{'to continue'|translate}}</small></h1></fieldset><fieldset><div class=\"panel panel-default\"><div class=list-group><button type=button ng-repeat=\"customer in vm.user.customers\" ng-class=\"{'list-group-item-info' : vm.isCustomerActive(customer)}\" ng-click=vm.setCustomerActive(customer) class=list-group-item>{{customer.customerName}} <i class=\"pull-right fa\" ng-class=\"{ 'fa-check-square': vm.isCustomerActive(customer), 'fa-square-o': !vm.isCustomerActive(customer) }\"></i></button></div></div></fieldset><footer><button type=submit class=\"btn btn-primary\">{{'Select'|translate}}</button><div class=btn-group><a class=\"btn btn-default\" ui-sref=auth.login>{{'Back'|translate}}</a></div></footer></form>"
  );


  $templateCache.put('seed/auth/views/view.html',
    "<div class=row><div class=\"col-xs-12 col-sm-12 col-md-4 col-md-offset-4 lockscreen animated flipInY\"><div class=\"well well-light no-padding\" ui-view=auth></div></div></div>"
  );


  $templateCache.put('seed/components/activities/activities.html',
    "<span id=activity class=activity-dropdown ng-style=\"{'background-image':'url('+ vm.user.avatar +')'}\"><b class=\"badge bounceIn animated\">0</b></span><div class=ajax-dropdown><div class=\"btn-group btn-group-justified\" data-toggle=buttons><label class=\"btn btn-default\" ng-class=\"{active : isActive(type.name)}\" ng-repeat=\"(key, value) in vm.activities\" ng-click=vm.setTab(type.name)><input type=radio name=activity> {{value.label}}</label></div><div class=\"ajax-notifications custom-scroll\"><div class=\"alert alert-transparent text-center\"><h4>Click a button to show messages here</h4>This blank page message helps protect your privacy, or you can show the first message here automatically.</div><i class=\"fa fa-lock fa-4x fa-border\"></i></div><span>Last updated on: {{vm.activities.last_update}} <button type=button ng-click=vm.refresh() data-loading-text=\"<i class='fa fa-refresh fa-spin'></i> Loading...\" class=\"btn btn-xs btn-default pull-right\"><i class=\"fa fa-refresh\"></i></button></span></div>"
  );


  $templateCache.put('seed/components/breadcrumbs/neoStateBreadcrumbs.html',
    "<ol class=breadcrumb><li ng-repeat=\"crumb in vm.crumbs\"><a type=button ui-sref={{crumb.stateName}}>{{crumb.title}}</a></li></ol>"
  );


  $templateCache.put('seed/components/customer/neoCustomerSwitcher.html',
    "<div class=\"project-context hidden-xs dropdown\"><span class=label>{{::vm.user.email}}</span><div class=dropdown dropdown><div class=\"project-selector dropdown-toggle\" dropdown-toggle><span ng-bind-html=vm.activeCustomer.customerName></span> <i class=\"fa fa-angle-down\"></i></div><ul class=dropdown-menu><li ng-repeat=\"customer in vm.customerCollection\"><a ng-click=vm.setActiveCustomer(customer)><i ng-show=\"customer.customerId === vm.activeCustomer.customerId\" class=\"fa fa-hand-o-right\"></i> {{customer.customerName}}</a></li></ul></div></div>"
  );


  $templateCache.put('seed/components/euLogotypes/neoEuLogotypes.html',
    "<img src=\"assets/seed/img/euLogotypes/eu-rozwoj-logo.jpg\"> <img src=\"assets/seed/img/euLogotypes/eu-ig-logo.jpg\"><p>Projekt współfinansowany ze środków Europejskiego Funduszu Rozwoju Reginalnego w ramach Programu Operacyjnego Innowacyjna Gospodarka.</p>"
  );


  $templateCache.put('seed/components/language/neoLanguageSwitcher.html',
    "<ul class=\"header-dropdown-list hidden-xs\"><li class=dropdown dropdown><a class=dropdown-toggle dropdown-toggle><img class=\"flag flag-{{vm.activeLanguage.code}}\"> <span>{{vm.activeLanguage.name}}</span> <i class=\"fa fa-angle-down\"></i></a><ul class=\"dropdown-menu pull-right\"><li ng-class=\"{active: language === vm.activeLanguage}\" ng-repeat=\"language in vm.languageCollection\"><a ng-click=vm.setLanguageActive(language)><img class=\"flag flag-{{language.code}}\"> {{language.name}}</a></li></ul></li></ul>"
  );


  $templateCache.put('seed/components/navigation/neoNavigationGroup.html',
    "<li ng-class=\"{'active': vm.isActive()}\"><a ng-click=vm.toggleGroup()><i ng-if=vm.icon class=\"fa fa-lg fa-fw {{vm.icon}}\"></i> <span>{{vm.label}}</span> <b class=collapse-sign><em class=fa ng-class=\"vm.isOpen()?'fa-minus-square-o':'fa-plus-square-o'\"></em></b></a><ul ng-transclude></ul></li>"
  );


  $templateCache.put('seed/components/navigation/neoNavigationItem.html',
    "<li ui-sref-active=active><a ui-sref={{vm.state}} ng-click=vm.toggleItem()><i ng-if=vm.icon class=\"fa fa-lg fa-fw {{vm.icon}}\"></i> <span>{{vm.label}}</span></a></li>"
  );


  $templateCache.put('seed/forms/select/partials/match.html',
    "<div class=ui-select-match ng-hide=$select.open ng-disabled=$select.disabled ng-class=\"{'btn-default-focus':$select.focus}\"><span tabindex=-1 class=\"btn btn-default form-control ui-select-toggle\" aria-label=\"{{ $select.baseTitle }} activate\" ng-disabled=$select.disabled ng-click=$select.activate() style=\"outline: 0\"><span ng-show=$select.isEmpty() class=\"ui-select-placeholder text-muted\">{{$select.placeholder}}</span><p ng-hide=$select.isEmpty() class=\"ui-select-match-text neo-select-match pull-left\" ng-class=\"{'ui-select-allow-clear': $select.allowClear && !$select.isEmpty()}\" ng-transclude=\"\"></p><i class=\"caret pull-right\" ng-click=$select.toggle($event)></i> <a ng-show=\"$select.allowClear && !$select.isEmpty()\" aria-label=\"{{ $select.baseTitle }} clear\" ng-click=$select.clear($event) class=\"btn btn-xs btn-link pull-right neo-select-clear\"><i class=\"fa fa-trash-o\" aria-hidden=true></i></a></span></div>"
  );


  $templateCache.put('seed/layout/partials/footer.html',
    "<div class=page-footer><div class=row><div class=\"col-xs-12 col-sm-6\"><span class=txt-color-white>SmartAdmin WebApp © 2013-2014</span></div><div class=\"col-xs-6 col-sm-6 text-right hidden-xs\"><div class=\"txt-color-white inline-block\"><i class=\"txt-color-blueLight hidden-mobile\">Last account activity <i class=\"fa fa-clock-o\"></i> <strong>52 mins ago &nbsp;</strong></i><div class=\"btn-group dropup\"><button class=\"btn btn-xs dropdown-toggle bg-color-blue txt-color-white\" data-toggle=dropdown><i class=\"fa fa-link\"></i> <span class=caret></span></button><ul class=\"dropdown-menu pull-right text-left\"><li><div class=padding-5><p class=\"txt-color-darken font-sm no-margin\">Download Progress</p><div class=\"progress progress-micro no-margin\"><div class=\"progress-bar progress-bar-success\" style=\"width: 50%\"></div></div></div></li><li class=divider></li><li><div class=padding-5><p class=\"txt-color-darken font-sm no-margin\">Server Load</p><div class=\"progress progress-micro no-margin\"><div class=\"progress-bar progress-bar-success\" style=\"width: 20%\"></div></div></div></li><li class=divider></li><li><div class=padding-5><p class=\"txt-color-darken font-sm no-margin\">Memory Load <span class=text-danger>*critical*</span></p><div class=\"progress progress-micro no-margin\"><div class=\"progress-bar progress-bar-danger\" style=\"width: 70%\"></div></div></div></li><li class=divider></li><li><div class=padding-5><button class=\"btn btn-block btn-default\">refresh</button></div></li></ul></div></div></div></div></div>"
  );


  $templateCache.put('seed/layout/partials/header.html',
    "<header id=header><div class=\"navbar-brand no-selection\"><img ng-src=\"{{::vm.appConf.generalSettings.logo}}\"> {{::vm.appConf.generalSettings.name}}</div><neo-activities></neo-activities><neo-customer-switcher></neo-customer-switcher><div class=pull-right><div id=hide-menu class=\"btn-header pull-right\"><span><a toggle-menu title=\"Collapse Menu\"><i class=\"fa fa-bars\"></i></a></span></div><div class=\"btn-header transparent pull-right\"><span><a full-screen title=\"Full Screen\"><i class=\"fa fa-arrows-alt\"></i></a></span></div><div id=speech-btn class=\"btn-header transparent pull-right hidden-sm hidden-xs\"><div><a title=\"Voice Command\" id=voice-command-btn speech-recognition><i class=\"fa fa-microphone\"></i></a><div class=\"popover bottom\"><div class=arrow></div><div class=popover-content><h4 class=vc-title>Voice command activated<br><small>Please speak clearly into the mic</small></h4><h4 class=\"vc-title-error text-center\"><i class=\"fa fa-microphone-slash\"></i> Voice command failed<br><small class=txt-color-red>Must <strong>\"Allow\"</strong> Microphone</small><br><small class=txt-color-red>Must have <strong>Internet Connection</strong></small></h4><a class=\"btn btn-success\" id=speech-help-btn>See Commands</a> <a class=\"btn bg-color-purple txt-color-white\" onclick=\"$('#speech-btn .popover').fadeOut(50)\">Close Popup</a></div></div></div></div><div id=logout class=\"btn-header transparent pull-right\"><span><a ui-sref=auth.logout title=\"{{'Sign Out'|translate}}\"><i class=\"fa fa-sign-out\"></i></a></span></div><neo-language-switcher></neo-language-switcher></div></header>"
  );


  $templateCache.put('seed/layout/partials/navigation.html',
    "<aside id=left-panel><nav><neo-navigation></neo-navigation><neo-eu-logotypes ng-if=vm.appConf.generalSettings.showEuLogotypes></neo-eu-logotypes></nav></aside>"
  );


  $templateCache.put('seed/layout/views/view.html',
    "<div ng-include=\"'seed/layout/partials/header.html'\"></div><div ng-include=\"'seed/layout/partials/navigation.html'\"></div><div id=main role=main><div id=ribbon><span class=ribbon-button-alignment><span id=refresh class=\"btn btn-ribbon\" reset-widgets tooltip-placement=bottom tooltip-html-unsafe=\"<i class='text-warning fa fa-warning'></i> Warning! This will reset all your widget settings.\"><i class=\"fa fa-refresh\"></i></span></span><neo-state-breadcrumbs></neo-state-breadcrumbs></div><div data-ui-view=content data-autoscroll=false></div></div>"
  );


  $templateCache.put('seed/tables/_directives/neoTableDateFilter/neoTableDateFilter.html',
    "<div class=\"icon-addon addon-sm\"><input neo-datepicker=\"{allowEmpty: true}\" ng-change=vm.changeDate() ng-model=vm.selectedDate class=\"form-control input-xs\" type=\"text\"><label class=\"fa fa-calendar\"></label><i class=\"fa fa-trash-o clear-btn\" aria-hidden=true ng-show=\"vm.selectedDate !== undefined\" ng-click=\"vm.selectedDate = undefined; vm.changeDate()\"></i></div>"
  );


  $templateCache.put('seed/tables/_directives/neoTableDatetimeFilter/neoTableDatetimeFilter.html',
    "<div class=\"icon-addon addon-sm\"><input neo-datepicker=\"{allowEmpty: true}\" ng-model=vm.selectedDate ng-change=vm.changeDate() type=text class=\"form-control input-xs\"><label class=\"fa fa-calendar\"></label><i class=\"fa fa-trash-o clear-btn\" aria-hidden=true ng-show=\"vm.selectedDate !== undefined\" ng-click=\"vm.selectedDate = undefined; vm.changeDate()\"></i></div>"
  );


  $templateCache.put('seed/tables/_directives/neoTableEnumFilter/neoTableEnumFilter.html',
    "<ui-select ng-model=vm.selectedItem on-select=\"vm.selectEnumItem($item, $model)\"><ui-select-match allow-clear=true><span>{{$select.selected[vm.displayProperty]}}</span></ui-select-match><ui-select-choices repeat=\"item[vm.displayProperty] as item in vm.filterableEnum.getEnumAsArray()\">{{item[vm.displayProperty]}}</ui-select-choices></ui-select>"
  );


  $templateCache.put('seed/tables/_directives/neoTableRelatedFilter/neoTableRelatedFilter.html',
    "<ui-select ng-model=vm.selectedItem on-select=\"vm.selectRelatedItem($item, $model)\"><ui-select-match allow-clear=true><span>{{$select.selected[vm.displayProperty]}}</span></ui-select-match><ui-select-choices repeat=\"item[vm.displayProperty] as item in vm.filteredCollection\" refresh=vm.filterCollection($select.search)>{{item[vm.displayProperty]}}</ui-select-choices></ui-select>"
  );


  $templateCache.put('seed/tables/filters/date-filter.html',
    "<neo-table-date-filter ng-model=params.filter()[name]></neo-table-date-filter>"
  );


  $templateCache.put('seed/tables/filters/enum-filter.html',
    "<neo-table-enum-filter enum-name=$column.data.enumName display-property=$column.data.displayProperty ng-model=params.filter()[name]></neo-table-enum-filter>"
  );


  $templateCache.put('seed/tables/filters/related-filter.html',
    "<neo-table-related-filter api-name=$column.data.apiName display-property=$column.data.displayProperty ng-model=params.filter()[name]></neo-table-related-filter>"
  );


  $templateCache.put('seed/tables/filters/text-filter.html',
    "<div class=\"icon-addon addon-sm\"><input type=text ng-model=params.filter()[name] class=\"form-control input-xs\"><label class=\"fa fa-search\"></label></div>"
  );


  $templateCache.put('seed/tables/filters/timestamp-filter.html',
    "<neo-table-datetime-filter ng-model=params.filter()[name]></neo-table-datetime-filter>"
  );


  $templateCache.put('seed/tables/partials/neo-filters.html',
    "<tr ng-show=show_filter><th ng-repeat=\"$column in $columns\" ng-show=$column.show(this) data-title-text=\"{{$column.titleAlt(this) || $column.title(this)}}\" class=\"neo-table-filter hasinput {{$column.class(this)}}\"><div ng-repeat=\"(name, filter) in $column.filter(this)\"><div ng-if=\"filter.indexOf('/') !== -1\" ng-include=filter></div><div ng-if=\"filter.indexOf('/') === -1\" ng-include=\"'ng-table/filters/' + filter + '.html'\"></div></div></th></tr>"
  );


  $templateCache.put('seed/tables/partials/neo-footer.html',
    "<div class=dt-toolbar-footer><div class=\"col-sm-8 hidden-xs neo-table-info\" style=\"position: absolute; overflow: visible\">{{'Showing'|translate}} {{params.total() ? (params.page() - 1) * params.count() + 1 : 0}} {{'to'|translate}} {{(params.page() * params.count()) &lt; params.total() ? params.page() * params.count() : params.total()}} {{'of'|translate}} {{params.total()}} {{'entries paginated by'|translate}}<div class=\"btn-group btn-group-sm\" dropdown><button type=button class=\"btn btn-default dropdown-toggle\" dropdown-toggle>{{ params.count() }} <span class=caret></span></button><ul class=\"dropdown-menu dropup\" role=menu><li ng-repeat=\"count in params.settings().counts track by $index\"><a ng-click=params.count(count)>{{count}}</a></li></ul></div>{{'items per page'|translate}}</div><div class=\"col-xs-12 col-sm-4 neo-table-paginate\"><ul class=pagination><li ng-class=\"{'disabled': !page.active}\" ng-repeat=\"page in pages\" ng-switch=page.type><a ng-switch-when=prev ng-click=params.page(page.number)>{{'Previous'|translate}}</a> <a ng-switch-when=first ng-click=params.page(page.number)><span ng-bind=page.number></span></a> <a ng-switch-when=page ng-click=params.page(page.number)><span ng-bind=page.number></span></a> <a ng-switch-when=more ng-click=params.page(page.number)>...</a> <a ng-switch-when=last ng-click=params.page(page.number)><span ng-bind=page.number></span></a> <a ng-switch-when=next ng-click=params.page(page.number)>{{'Next'|translate}}</a></li></ul></div></div>"
  );


  $templateCache.put('seed/tables/partials/neo-header.html',
    "<ng-table-sorter-row></ng-table-sorter-row><ng-table-filter-row></ng-table-filter-row>"
  );


  $templateCache.put('seed/tables/partials/neo-sorting.html',
    "<tr><th title={{$column.headerTitle(this)}} ng-repeat=\"$column in $columns\" ng-click=\"sortBy($column, $event)\" ng-if=$column.show(this) ng-init=\"template=$column.headerTemplateURL(this)\" class=\"no-selection {{$column.class(this)}}\" ng-class=\"{'sorting': $column.sortable(this),'sorting-asc': params.sorting()[$column.sortable(this)]=='asc','sorting-desc': params.sorting()[$column.sortable(this)]=='desc'}\"><div ng-if=!template ng-show=!template ng-bind=$column.title(this) class=neo-table-header></div><div ng-if=template ng-include=template></div></th></tr>"
  );
 $log.debug('Initiated module');});});
