define('apps/docs/_models/DocumentSharing/DocumentSharing',[
	'apps/docs/module'
], function (module) {
	'use strict';

	/**
	 * @class DocumentSharing
	 * @implements {seed.BaseModel}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Console log provider
	 * @param restmod {Object} Data model layer interface
	 * @return {*|Model}
	 * @param ShareStatusesEnum
	 */
	function DocumentSharing($log, restmod, ShareStatusesEnum) {
		$log.debug('Initiating model factory');

		return restmod
			.model('/documents/share')
			.mix({
				status: {
					encode: 'EnumEncode',
					decode: 'EnumDecode',
					param: ShareStatusesEnum
				},
				privilege: {
					hasOne: 'Privilege'
				},
				entries: {
					hasMany: 'PrivilegeEntry'
				},


				// HOOKS
				$hooks: {
					// Ensure that composite models are encoded in order to allow recurrence
					// saving with one request made
					'before-render': function (raw) {
						if (!_.isEmpty(this.privilege)) {
							raw.privilege = this.privilege.$encode(null);
						}
					}
				},

				$extend: {
					Record: {
						$share: function () {
							return this.$send({
								method: 'POST',
								url: this.$scope.$url(),
								data: this.$encode()
							});
						},

						$deleteProvided: function () {
							return this.$send({
								method: 'DELETE',
								url: this.$scope.$url() + '/' + this.id + '?documentId=' + this.documentId,
							});
						},

						$updateProvided: function () {
							return this.$send({
								method: 'PUT',
								url: this.$scope.$url() + '/' + this.id,
								data: this.$encode()
							});
						},

						$deleteAccepted: function () {
							return this.$send({
								method: 'DELETE',
								url: this.$scope.$url() + '/accepted?documentId=' + this.documentId
							});
						},

						$accept: function () {
							return this.$send({
								method: 'PUT',
								url: this.$scope.$url() + '/toaccept/' + this.token + '/accept?customerId=' + this.customerId
							});
						},

						$reject: function () {
							return this.$send({
								method: 'PUT',
								url: this.$scope.$url() + '/toaccept/' + this.token + '/reject',
								data: this.$encode()
							});
						}
					},

					Collection: {
						$getProvided: function (_params) {
							return this.$action(function () {
								var request = {
									method: 'GET',
									url: this.$url('fetchMany') + '/provided',
									params: this.$params
								};

								if (_params) {
									request.params = request.params ? extend(request.params, _params) : _params;
								}

								this.$send(request, function (_response) {
									this.$unwrap(_response.data);
								});
							});
						},
						$getAcceptedList: function (_params) {
							return this.$action(function () {
								var request = {
									method: 'GET',
									url: this.$url('fetchMany') + '/accepted',
									params: this.$params
								};

								if (_params) {
									request.params = request.params ? extend(request.params, _params) : _params;
								}

								this.$send(request, function (_response) {
									this.$unwrap(_response.data);
								});
							});
						},

						$getToAcceptList: function (_params) {
							return this.$action(function () {
								var request = {
									method: 'GET',
									url: this.$url('fetchMany') + '/toaccept',
									params: this.$params
								};

								if (_params) {
									request.params = request.params ? extend(request.params, _params) : _params;
								}

								this.$send(request, function (_response) {
									this.$unwrap(_response.data);
								});
							});
						}
					}
				}
			});
	}

	module.factory('DocumentSharing', DocumentSharing);
});

