define('seed/graphs/includes',[
	'./morris/morrisDonutGraph',
	'./morris/morrisAreaGraph',
	'./morris/morrisLineGraph',
	'./morris/morrisBarGraph'
], function () {
	'use strict';
});



