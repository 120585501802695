define('apps/module.conf',['angular'], function() { 

 'use strict';
 return angular.module('app.conf', [])

.constant('appConf', {appsSettings:[{name:'Docs',path:'apps/docs',order:0}],environmentSettings:{debugEnabled:false,modRewriteEnabled:true,predefinedLogins:[],apiUrl:'http://app.tosend.pl:9500/api/v1/',cookiesProviderConfig:{domain:'tosend.pl'}},generalSettings:{name:'neoDocs',logo:'assets/docs/img/neoDocsLogo.png',defaultStateToRedirectAfterLogin:'app.docs.documents.owned',showEuLogotypes:true},languageSettings:{defaultLanguage:{name:'English',code:'gb',locale:'en_GB'},languageCollection:[{name:'Polski',code:'pl',locale:'pl_PL'},{name:'English',code:'gb',locale:'en_GB'}]},skinSettings:{defaultSkin:'smart-style-0',skins:[{name:'smart-style-0',logo:'assets/img/logo.png',class:'btn btn-block btn-xs txt-color-white margin-right-5',style:'background-color:#4E463F;',label:'Smart Default'}]}})

; 

});
