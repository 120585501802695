define('apps/docs/templates/_includes',[
	// Documents
	'./documents/module',
	'./documents/_includes',

	// Documents
	'./fields/module',
	'./fields/_includes'
], function () {
	'use strict';
});

