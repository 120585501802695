define('apps/docs/_directives/docsViewer/docsViewer',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Directive responsible for rendering field tree
	 * @class docsEditor
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Logging service
	 * @return {{restrict: string, templateUrl: string, controllerAs: string, scope: {container:
	 *   string}, controller: Function}}
	 */
	function docsViewer($log) {

		$log = $log.getInstance('app.docs.docsViewer');
		$log.debug('Initiated directive');

		return {
			restrict: 'EA',
			templateUrl: 'apps/docs/_directives/docsViewer/docs-viewer.html',
			controllerAs: 'vm',
			scope: {
				container: '='
			},

			controller: function () {
				var vm = this;

				vm.previewMode = true;

				// variables
				vm.nestedGridsterOptions = {
					// Row height settings
					rowHeight: 20,
					minSizeY: 4,
					defaultSizeY: 4,
					// Row width settings
					minSizeX: 2,
					maxSizeX: 6,
					defaultSizeX: 6,
					resizable: {
						enabled: false
					},
					draggable: {
						enabled: false
					}
				};

				vm.gridsterOpts = {
					resizable: {
						enabled: false
					},
					draggable: {
						enabled: false
					}
				};

				$log.debug('Initiated controller');
			}
		};
	}

	module.directive('docsViewer', docsViewer);
});

