define('apps/docs/_models/Attachment/AttachmentAPI',['apps/docs/module', 'file-saver'], function (module, saveAs) {
	'use strict';

	/**
	 * Interface for REST communication with server
	 * @constructor ProjectAPI
	 * @implements {seed.BaseAPI}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Logging service
	 * @param BaseAPI {Function} Base interface for REST communication with server
	 * @param Attachment {Object} Data model class
	 * @param Upload {Object} File upload service
	 * @param appConf {Object} Application configuration
	 * @return {Function} Instantiated service
	 */
	function AttachmentAPI($log, BaseAPI, Attachment, Upload, appConf) {

		$log = $log.getInstance('app.docs.AttachmentAPI');
		$log.debug('Instantiated API service');

		var api = new BaseAPI(Attachment);

		api.upload = function (model) {
			$log.debug('Called AttachmentAPI "upload" method');

			return Upload
				.upload({
					url: appConf.environmentSettings.apiUrl + 'attachments',
					file: Upload.rename(model, model.name.replace(/[^\x00-\x7F]/g, '_'))
				});
		};

		api.download = function (attachment) {
			$log.debug('Called AttachmentAPI "download" method to download file with ID: ' +
				attachment.id);

			return attachment
				.$download()
				.$asPromise()
				.then(function (attachment) {
					var blob = new Blob([attachment.$response.data], {type: 'text/plain'});
					saveAs(blob, attachment.name);
				})
				.catch(function () {
					$log.error('Could not download the file');
				});
		};

		return api;
	}

	module.service('AttachmentAPI', AttachmentAPI);
});




