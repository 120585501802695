define('apps/docs/_models/Validators/ColorValidator',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Defines extend class of Validator holding color validator model initial properties
	 * @class ColorValidator
	 * @mixes {app.docs.Validator}
	 * @memberOf app.docs
	 *
	 * @example
	 * {
	 *  validatorType: 'color'
	 *  type: 'hex'
	 * }
	 * @see {@link http://formvalidation.io/validators/color/}
	 * @param $log {Object} Logging service
	 * @param restmod {Object} Object Relational Mapper interface
	 * @return {*|Model} Model instance
	 */
	function ColorValidator($log, restmod) {

		$log = $log.getInstance('app.docs.ColorValidator');
		$log.debug('Created new instance');

		return restmod
			.model()
			.mix('Validator', {
				$templateUrl: {
					init: 'apps/docs/_directives/docsValidator/validators/color.html'
				},
				// The type of color
				type: {
					init: 'hex'
				}
			});
	}

	module.factory('ColorValidator', ColorValidator);
});

