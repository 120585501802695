/**
 * @namespace app
 */

define('apps/module',['angular', 'angular-gettext'], function (ng) {
	'use strict';

	var container = ng.module('app', [
		'seed',
		'app.conf',
		'app.docs'
	]);

	container.config(function ($cookiesProvider, appConf) {
		_.extend($cookiesProvider, appConf.environmentSettings.cookiesProviderConfig);
	});

	container.run(function ($log, $rootScope, neoTemplateLoader) {
		$log = $log.getInstance('app.module');

		neoTemplateLoader.load('apps/docs/loginTemplate.html', 'seed/auth/login/forms/login/authLoginForm.html');

		$rootScope.appReady = true;

		$log.debug('Set up container configuration');
	});

	return container;
});

