define('apps/docs/_models/Privilege/Privilege',[
	'apps/docs/module'
], function (module) {
	'use strict';

	/**
	 * @class Privilege
	 * @implements {seed.BaseModel}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Console log provider
	 * @param restmod {Object} Data model layer interface
	 * @param PermissionTypesEnum
	 * @return {*|Model}
	 */
	function Privilege($log, restmod, PermissionTypesEnum) {

		$log = $log.getInstance('app.docs.Privilege');
		$log.debug('Initiating model factory');

		return restmod
			.model()
			.mix({
				type:{
					encode: 'EnumEncode',
					decode: 'EnumDecode',
					param: PermissionTypesEnum,
					init: PermissionTypesEnum.getValueByKey('READ')
				}
			});
	}

	module.factory('Privilege', Privilege);
});

