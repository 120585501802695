define('apps/docs/_directives/versionSelect/versionSelect',[
	'apps/docs/module'
], function (module) {
	'use strict';

	/**
	 * Enables switching between model version using app ui-router states
	 * @class versionSelect
	 * @memberOf app.docs
	 *
	 * @example
	 * <version-select model="model"></version-select>
	 *
	 * @param $log {Object} Logging service
	 * @param $stateParams {Object} Current request param provider
	 * @param $state {Object} UI-Router state service
	 * @param $stateParams {Object} State params provider
	 * @return {{restrict: string, templateUrl: string, scope: {model: string}, controller: Function}}
	 */
	function versionSelect($log, $stateParams, $state) {

		$log = $log.getInstance('app.docs.versionSelect');
		$log.debug('Initiated directive');

		return {
			restrict: 'EA',
			templateUrl: 'apps/docs/_directives/versionSelect/version-select.html',
			controllerAs: 'vm',
			scope: {
				model: '='
			},
			controller: function ($scope) {
				var vm = this;

				// variables
				// functions
				vm.changeDocumentVersion = changeDocumentVersion;

				/**
				 * Switches between document versions
				 * @param newVersion {Object} version number to be changed to
				 */
				function changeDocumentVersion(newVersion) {
					// If switching to newest version omit postfix version
					//noinspection JSUnresolvedVariable
					if (_.isEqual(_.first($scope.model.versions), newVersion)) {
						$log.debug('Switching to document newest version');

						$state.go($state.current, {id: $stateParams.id, version: null});
					} else {
						$log.debug('Switching to document version: ' + newVersion.version);

						$state.go($state.current, {id: $stateParams.id, version: newVersion.version});
					}
				}

				$log.debug('Initiated controller');
			}
		};
	}

	module.directive('versionSelect', versionSelect);
});

