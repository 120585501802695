define('seed/forms/_includes',[
	// Validate
	'./validate/neoValidate',

	// Select
	'./select/services/neoSelect',

	// Input
	'./input/neoDatepicker'
], function () {
	'use strict';
});

