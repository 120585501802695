define('apps/docs/_models/PrivilegeEntry/PrivilegeEntry',[
	'apps/docs/module'
], function (module) {
	'use strict';

	/**
	 * @class PrivilegeEntry
	 * @implements {seed.BaseModel}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Console log provider
	 * @param restmod {Object} Data model layer interface
	 * @param PermissionTypesEnum
	 * @return {*|Model}
	 */
	function PrivilegeEntry($log, restmod, PermissionTypesEnum) {

		$log = $log.getInstance('app.docs.PrivilegeEntry');
		$log.debug('Created new instance');

		return restmod
			.model()
			.mix({
				'privilege.type':{
					encode: 'EnumEncode',
					decode: 'EnumDecode',
					param: PermissionTypesEnum,
					init: PermissionTypesEnum.getValueByKey('READ')
				}
			});
	}

	module.factory('PrivilegeEntry', PrivilegeEntry);
});
/**
 * Created by michu on 09.07.15.
 */
;
