define('apps/docs/documents/shared/widgets/sharedDocumentListWidget/docsSharedDocumentListWidget',['apps/docs/documents/shared/module'], function (module) {
	'use strict';

	/**
	 * Renders list of user's documents
	 * @class docsDocumentListWidget
	 * @memberOf app.docs.documents
	 *
	 * @param neoTableParams {Function} Table configuration object.
	 * @return {{restrict: string, templateUrl: string, scope: boolean, controllerAs: string,
	 *   controller: Function}}
	 * @param DocumentSharingAPI
	 * @param docsSharedTableConfigFactory
	 * @param $modal
	 * @param $log
	 */
	function docsSharedDocumentListWidget(neoTableParams, DocumentSharingAPI,
		docsSharedTableConfigFactory, $modal, $log) {
		return {
			restrict: 'EA',
			templateUrl: 'apps/docs/documents/shared/widgets/sharedDocumentListWidget/docsSharedDocumentListWidget.html',
			scope: true,
			controllerAs: 'vm',

			controller: function ($scope) {
				var vm = this;

				// variables
				vm.documentTableOptions = neoTableParams(DocumentSharingAPI, {
					getData: function ($defer, params) {
						var queryParams = {
							first: (params.url().page - 1) * params.url().count,
							pageSize: params.url().count,
							sort: params.sorting(),
							filter: params.filter()
						};

						return DocumentSharingAPI
							.getAcceptedList(_.assign({}, queryParams))
							.then(function (models) {
								params.total(models.$metadata.total);
								$defer.resolve(models);

								$log.debug('Initiated accepted table');

								return models;
							});
					}
				});

				docsSharedTableConfigFactory.waitingTable = vm.documentTableOptions;
				// functions
				vm.deleteDocument = deleteDocument;
				vm.openShareModal = openShareModal;

				/**
				 * opens Share Modal
				 * @method openShareModal
				 * @param documentId {app.docs.DocumentTemplate} Model to be removed
				 * @param shareFlag
				 */
				function openShareModal(documentId, shareFlag) {
					// $scope.modalInstance is required workaround to access injected $modalInstance
					// functions (dismiss, close) inside directives
					$scope.shareFlag = shareFlag;
					$scope.documentId = documentId;

					$log.debug('Opening share modal');


					$scope.modalInstance = $modal.open({
						template: '<docs-share-modal></docs-share-modal>',
						scope: $scope
					});

				}

				/**
				 * Removes selected document template
				 * @method deleteDocumentTemplate
				 * @param documentId
				 */
				function deleteDocument(documentId) {
					DocumentSharingAPI
						.deleteAccepted(DocumentSharingAPI.build({documentId: documentId}))
						.then(function () {
							$log.debug('Deleting document from shared with me list');
							vm.documentTableOptions.reload();
						});
				}
			}
		};
	}

	module.directive('docsSharedDocumentListWidget', docsSharedDocumentListWidget);

});

