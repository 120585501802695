define('apps/docs/_models/Version/Version',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Generic that handles object versioning
	 * @class Version
	 * @implements {seed.BaseModel}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Logging service
	 * @param restmod {Object} Data model layer interface
	 * @return {*|Model} Model instance
	 */
	function Version($log, restmod) {

		$log = $log.getInstance('app.docs.Version');
		$log.debug('Created new instance');

		return restmod
			.model('version')
			.mix();
	}

	module.factory('Version', Version);
});

