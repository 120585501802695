define('apps/_includes',[
	// Seed
	'seed/_includes',
	'seed/module',

	// Docs
	'apps/docs/_includes',
	'apps/docs/module',

	// Load config
	'apps/module.conf'
], function () {
	'use strict';
});

