define('apps/docs/_models/SharedDocument/SharedDocument',[
	'apps/docs/module'
], function (module) {
	'use strict';

	//noinspection JSClosureCompilerSyntax
	/**
	 * @class SharedDocument
	 * @implements {seed.BaseModel}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Console log provider
	 * @param restmod {Object} Data model layer interface
	 * @return {*|Model}
	 */
	function SharedDocument($log, restmod) {

		$log = $log.getInstance('app.docs.SharedDocument');
		$log.debug('Created new instance');

		return restmod
			.model('/documents/share/mine')
			.mix('Document');
	}

	module.factory('SharedDocument', SharedDocument);
});

