define('apps/docs/_models/DocumentSharing/DocumentSharingAPI',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Interface for REST communication with server
	 * @constructor DocumentSharingAPI
	 * @implements {seed.BaseAPI}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Logging service
	 * @param BaseAPI {Function} Base interface for REST communication with server
	 * @param DocumentSharing {Object} Data model class
	 * @return {Function} Instantiated service
	 * @param appMessages
	 */
	function DocumentSharingAPI($log, BaseAPI, DocumentSharing, appMessages) {

		$log.debug('Instantiated API service');
		var api = new BaseAPI(DocumentSharing);

		// Sharing with email and documentIs as a parameters

		api.share = function (model) {
			return model
				.$share()
				.$asPromise()
				.catch(api.handleError);
		};

		// Sharing to someone

		api.getProvided = function (_params) {
			return DocumentSharing
				.$collection()
				.$getProvided(_params)
				.$asPromise()
				.catch(api.handleError);
		};

		api.deleteProvided = function (model) {
			return model
				.$deleteProvided()
				.$asPromise()
				.catch(api.handleError);
		};

		api.updateProvided = function (model) {
			return model
				.$updateProvided()
				.$asPromise()
				.catch(API.handleError);
		};

		api.getAcceptedList = function (_params) {
			return DocumentSharing
				.$collection()
				.$getAcceptedList(_params)
				.$asPromise()
				.catch(api.handleError);
		};

		api.deleteAccepted = function (model) {
			return model
				.$deleteAccepted()
				.$asPromise()
				.catch(api.handleError);
		};

		api.getToAcceptList = function (_params) {
			return DocumentSharing
				.$collection()
				.$getToAcceptList(_params)
				.$asPromise()
				.catch(api.handleError);
		};

		api.accept = function (model) {
			return model
				.$accept()
				.$asPromise()
				.then(function () {
					appMessages.success('Accepted');
				})
				.catch(api.handleError);
		};

		api.reject = function (model) {
			return model
				.$reject()
				.$asPromise()
				.then(function () {
					appMessages.success('Rejected');
				})
				.catch(api.handleError);
		};



		return api;
	}

	module.service('DocumentSharingAPI', DocumentSharingAPI);
});




