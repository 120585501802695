/**
 * Created by michu on 08.07.15.
 */
define('apps/docs/_directives/shareAcceptanceModal/shareAcceptanceModal',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * @class shareAcceptanceModal
	 * @memberOf app.docs.templates.documents
	 *
	 * @param $log {Object} Logging service
	 * @param $cookies
	 * @param DocumentSharingAPI
	 * @return {{restrict: string, templateUrl: string, controllerAs: string, controller: Function}}
	 */
	function shareAcceptanceModal($log, $cookies, DocumentSharingAPI) {

		$log = $log.getInstance('app.docs.shareAcceptanceModal');
		$log.debug('Initiated directive');

		return {
			restrict: 'E',
			templateUrl: 'apps/docs/_directives/shareAcceptanceModal/share-acceptance-modal.html',
			controllerAs: 'vm',
			controller: function ($scope) {
				var vm = this;

				// variables
				vm.user = $scope.$root.user;
				vm.chosenCustomer = undefined;
				vm.documentSharing = DocumentSharingAPI.build({token: $scope.token});

				// functions
				vm.closeModal = closeModal;
				vm.accept = accept;
				vm.reject = reject;
				vm.chooseCustomer = chooseCustomer;
				vm.isCustomerActive = isCustomerActive;


				function chooseCustomer(customer) {
					vm.chosenCustomer = customer;

					$log.debug('Chosen customer to accept sharing is: ' + customer.customerName);
				}

				function isCustomerActive(customer) {
					//noinspection JSUnresolvedVariable
					return vm.chosenCustomer === customer;
				}


				function accept() {
					if (!_.isUndefined(vm.chosenCustomer)) {
						vm.documentSharing.customerId = vm.chosenCustomer.customerId;
					} else {
						vm.documentSharing.customerId = $cookies.getObject('activeCustomer');
					}
					DocumentSharingAPI
						.accept(vm.documentSharing)
						.then(function () {
							$scope.$parent.modalInstance.dismiss(true);
						});
				}

				function reject() {
					DocumentSharingAPI
						.reject(vm.documentSharing)
						.then(function () {

							$scope.$parent.modalInstance.dismiss(false);
						});
				}

				function closeModal() {
					$scope.$parent.modalInstance.dismiss();
					$log.debug('Closed share modal');
				}

				$log.debug('Initiated controller');
			}
		};
	}

	module.directive('shareAcceptanceModal', shareAcceptanceModal);
});

