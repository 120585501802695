define('apps/docs/_models/_includes',[
	'./Attachment/Attachment',
	'./Attachment/AttachmentAPI',

	'./Document/Document',
	'./Document/DocumentAPI',

	'./DocumentSharing/DocumentSharing',
	'./DocumentSharing/DocumentSharingAPI',

	'./Privilege/Privilege',

	'./PrivilegeEntry/PrivilegeEntry',

	'./SharedDocument/SharedDocument',
	'./SharedDocument/SharedDocumentAPI',

	'./DocumentTemplate/DocumentTemplate',
	'./DocumentTemplate/DocumentTemplateAPI',

	'./Field/Field',
	'./Field/FieldAPI',

	'./Field/EmailField',
	'./Field/SelectField',
	'./Field/TextareaField',
	'./Field/PhoneField',
	'./Field/TimeField',
	'./Field/FileField',
	'./Field/ColorField',
	'./Field/DateField',
	'./Field/NumberField',
	'./Field/TextField',
	'./Field/UrlField',
	'./Field/SelectField',
	'./Field/MultiselectField',

	'./CompositeField/CompositeField',
	'./CompositeField/CompositeFieldAPI',

	'./Validators/Validator',
	'./Validators/ValidatorAPI',

	'./Validators/EmailAddressValidator',
	'./Validators/StringLengthValidator',
	'./Validators/NotEmptyValidator',
	'./Validators/DateValidator',
	'./Validators/PhoneValidator',
	'./Validators/IntegerValidator',
	'./Validators/ColorValidator',
	'./Validators/UriValidator',

	'./Version/Version',
	'./Version/VersionAPI'
], function () {
	'use strict';
});

