define('apps/docs/documents/shared/widgets/sharedToAcceptListWidget/sharedToAcceptListWidget',['apps/docs/documents/shared/module'], function (module) {
	'use strict';

	/**
	 * Renders list of user's documents
	 * @class sharedToAcceptListWidget
	 * @memberOf app.docs.documents
	 *
	 * @param DocumentSharingAPI {Object} API interface for server communication.
	 * @return {{restrict: string, templateUrl: string, scope: boolean, controllerAs: string,
	 *   controller: Function}}
	 * @param $modal
	 * @param docsSharedTableConfigFactory
	 * @param $cookies
	 * @param $stateParams
	 * @param neoTableParams
	 * @param $log
	 */
	function sharedToAcceptListWidget(DocumentSharingAPI, $log, $modal, $cookies, $stateParams,
		docsSharedTableConfigFactory, neoTableParams) {
		return {
			restrict: 'EA',
			templateUrl: 'apps/docs/documents/shared/widgets/sharedToAcceptListWidget/sharedToAcceptListWidget.html',
			scope: true,
			controllerAs: 'vm',

			controller: function ($scope) {
				var vm = this;


				// variables
				vm.sharedDocumentsTableOptions = neoTableParams(DocumentSharingAPI, {
					getData: function ($defer, params) {
						var queryParams = {
							first: (params.url().page - 1) * params.url().count,
							pageSize: params.url().count,
							sort: params.sorting(),
							filter: params.filter()
						};

						return DocumentSharingAPI
							.getToAcceptList(_.assign({}, queryParams))
							.then(function (models) {
								params.total(models.$metadata.total);
								$defer.resolve(models);
								$log.debug('Initiated to accept table');

								return models;
							});
					}
				});
				vm.documentName = $scope.documentName;
				vm.entry = $scope.shareEntry;

				// functions
				vm.showModal = showModal;
				vm.accept = accept;
				vm.reject = reject;

				if ($stateParams.token) {
					showModal($stateParams.token);
				}

				/**
				 * @method showModal
				 * @param token
				 */

				function showModal(token) {
					// $scope.modalInstance is required workaround to access injected $modalInstance
					// functions (dismiss, close) inside directives
					$scope.token = token;
					$scope.modalInstance = $modal.open({
						template: '<share-acceptance-modal></share-acceptance-modal>',
						scope: $scope
					});

					$scope.modalInstance.opened.then(function (state) {
						if(state) {
							vm.sharedDocumentsTableOptions.reload();
						}
					});
					$log.debug('Initiated show modal instance');

				}

				function accept(token) {
					vm.documentSharing = DocumentSharingAPI.build({token: token});
					vm.documentSharing.customerId = $cookies.getObject('activeCustomer');
					DocumentSharingAPI
						.accept(vm.documentSharing)
						.then(function () {
							vm.sharedDocumentsTableOptions.reload();
							docsSharedTableConfigFactory.waitingTable.reload();
							$log.debug('Accepted share');
						});
				}


				function reject(token) {
					vm.documentSharing = DocumentSharingAPI.build({token: token});
					DocumentSharingAPI
						.reject(vm.documentSharing)
						.then(function () {
							vm.sharedDocumentsTableOptions.reload();
							$log.debug('Rejected share');
						});
				}


			}
		};
	}

	module.directive('sharedToAcceptListWidget', sharedToAcceptListWidget);

});

