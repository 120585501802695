define('apps/docs/_models/Document/DocumentAPI',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Interface for REST communication with server
	 * @constructor ProjectAPI
	 * @implements {seed.BaseAPI}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Logging service
	 * @param BaseAPI {Function} Base interface for REST communication with server
	 * @param Document {Object} Data model class
	 * @return {Function} Instantiated service
	 */
	function DocumentAPI($log, BaseAPI, Document) {

		$log = $log.getInstance('app.docs.DocumentAPI');
		$log.debug('Instantiated API service');

		return new BaseAPI(Document);
	}

	module.service('DocumentAPI', DocumentAPI);
});




