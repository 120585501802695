define('apps/docs/_directives/docsValidator/docsValidator',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Directive responsible for rendering single field validator
	 * @class docsValidator
	 * @memberOf app.docs
	 *
	 * @param $http HTTP communication service
	 * @param $compile {Function} Template compilation service
	 * @param $log {Object} Logging service
	 * @param $templateCache {Object} Template caching service
	 * @return {{restrict: string, controllerAs: string, scope: {validator: string, container:
	 *   string}, controller: Function, link: Function}}
	 */
	function docsValidator($http, $compile, $log, $templateCache) {

		$log = $log.getInstance('app.docs.docsValidator');
		$log.debug('Initiated directive');

		return {
			restrict: 'EA',
			controllerAs: 'vm',
			scope: {
				validator: '=',
				container: '='
			},

			link: function (scope, element) {

				if (!_.has(scope.validator, '$templateUrl')) {
					$log.error(scope.validator.validatorType +
						' validator does not have $templateUrl attached');
					return;
				}

				var template = $templateCache.get(scope.validator.$templateUrl);

				if (template) {
					updateElementContents(template);

					$log.debug('Loaded ' + scope.validator.$templateUrl + ' template from cache');
				} else {
					$http
						.get(scope.validator.$templateUrl)
						.then(function (data) {
							updateElementContents(data.data);
						})
						.catch(function () {
							$log.error('Could not fetch validator template');
						});
				}

				function updateElementContents(template) {
					element.html(template);
					$compile(element.contents())(scope);
				}

				$log.debug('Called linking function');
			},

			controller: function ($scope) {
				var vm = this;

				// variables
				// functions
				vm.removeValidator = removeValidator;

				function removeValidator() {
					$('#fieldTemplate')
						.formValidation('enableFieldValidators', $scope.container.$name, false, $scope.validator.validatorType.formValidationKey);

					$scope.container.validators.$remove($scope.validator);

					$log.debug('Removed validator form field validators list');
				}

				$log.debug('Initiated controller');
			}
		};
	}

	module.directive('docsValidator', docsValidator);
});

