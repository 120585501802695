define('apps/docs/_models/Document/Document',[
	'apps/docs/module'
], function (module) {
	'use strict';

	//noinspection JSClosureCompilerSyntax
	/**
	 * @class Document
	 * @implements {seed.BaseModel}
	 * @memberOf app.docs
	 *
	 * @param $log {Object} Console log provider
	 * @param restmod {Object} Data model layer interface
	 * @return {*|Model} Model instance
	 */
	function Document($log, restmod) {

		$log = $log.getInstance('app.docs.Document');
		$log.debug('Initiating model factory');

		return restmod
			.model('/documents')
			.mix('DocumentTemplate', {

				// model configuration
				$config: {
					name: 'Document'
				},

				// attribute modifiers and relations
				template: {
					belongsTo: 'DocumentTemplate'
				},
				name: {
					init: 'New document'
				}

				// methods
			});
	}

	module.factory('Document', Document);
});

