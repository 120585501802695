define('apps/docs/_directives/docsField/docsField',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Directive responsible for rendering single form field
	 * @class docsField
	 * @memberOf app.docs
	 *
	 * @param $http HTTP {Object} communication service
	 * @param $compile {Function} Template compilation service
	 * @param $log {Object} Logging service
	 * @param $modal {Object} Bootstrap modal service
	 * @param $templateCache {Object} Template caching service
	 * @param AttachmentAPI {Object} API interface for server communication
	 * @return {{
	 *  restrict: string,
	 *  controllerAs: string,
	 *  scope: {field: string, container: string}, link: Function, controller: Function
	 *  }}
	 */
	function docsField($http, $compile, $modal, $templateCache, $log, $state, AttachmentAPI) {

		$log = $log.getInstance('app.docs.docsField');
		$log.debug('Initiated directive');

		return {
			restrict: 'EA',
			controllerAs: 'vm',
			scope: {
				previewMode: '=',
				field: '=',
				container: '='
			},

			link: function (scope, element) {
				if (!_.has(scope.field, '$templateUrl')) {
					// If field is composite skip linking
					$log.debug('Composite field without $templateUrl');
					return;
				}

				// Set default value when creating docs and field value is empty
				if ($state.includes('app.docs.documents') && _.isUndefined(scope.field.value)) {
					scope.field.value = scope.field.defaultValue;
				}

				// Pre-load template of field
				var template = $templateCache.get(scope.field.$templateUrl);

				if (template) {
					updateElementContents(template);

					$log.debug('Loaded ' + scope.field.$templateUrl + ' template from cache');
				} else {
					$http
						.get(scope.field.$templateUrl.toString())
						.then(function (data) {
							updateElementContents(data.data);
						})
						.catch(function () {
							$log.error('Could not fetch field template');
						});
				}

				function updateElementContents(template) {
					element.html(template);
					$compile(element.contents())(scope);

					// Attach validation rules
					if (!scope.previewMode) {
						scope.$applyAsync(function () {
							$('#fieldTemplate')
								.data('formValidation')
								.addField(scope.field.$name, scope.field.validators.$encapsulateValidators());

							$log.debug('Recompiled view with newly added field');
						});
					}

				}

				$log.debug('Called linking function');
			},

			controller: function ($scope) {
				var vm = this;

				// functions
				vm.deleteField = deleteField;
				vm.isCompositeElement = isCompositeElement;
				vm.openFieldPropertiesModal = openFieldPropertiesModal;
				vm.downloadAttachment = downloadAttachment;
				vm.clearAttachment = clearAttachment;

				/**
				 * Checks if element has nested fields
				 * @return {boolean} Truthy value
				 */
				function isCompositeElement() {
					return !!$scope.container.composite.length;
				}


				function downloadAttachment(attachment) {
					AttachmentAPI.download(attachment);
				}

				function clearAttachment(field) {
					field.value = null;
				}

				/**
				 * Removes field from collection
				 */
				function deleteField() {
					$('#fieldTemplate').formValidation('removeField', $scope.field.$name);

					$scope.container.composite.$remove($scope.field);
					$scope.field.$destroy();

					$log.debug('Removed field form container');
				}

				/**
				 * Open modal with field properties
				 */
				function openFieldPropertiesModal() {
					// $scope.modalInstance is required workaround to access injected $modalInstance
					// functions (dismiss, close) inside directives
					$scope.modalInstance = $modal.open({
						template: '<docs-field-properties></docs-field-properties>',
						size: 'lg',
						scope: $scope
					});

					$log.debug('Opened modal with field properties');
				}

				$log.debug('Initiated controller');
			}
		};
	}

	module.directive('docsField', docsField);
});

