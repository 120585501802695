define('apps/docs/_directives/_includes',[
	'./docsField/docsField',
	'./docsEditor/docsEditor',
	'./docsViewer/docsViewer',
	'./docsFieldProperties/docsFieldProperties',
	'./docsValidator/docsValidator',
	'./docsAddField/docsAddField',
	'./docsAddCompositeField/docsAddCompositeField',
	'./docsAddValidator/docsAddValidator',
	'./docsShareModal/docsShareModal',
	'./shareAcceptanceModal/shareAcceptanceModal',
	'./hoverClass/hoverClass',
	'./versionSelect/versionSelect'
], function () {
	'use strict';
});

