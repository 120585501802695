define('apps/docs/_enums/ShareStatusesEnum',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * @class ShareStatusesEnum
	 * @implements {app.BaseEnum}
	 * @memberOf {app.docs}
	 *
	 * @param BaseEnum {Function} Augmentation of enum-type object
	 * @param gettext {Function} Translation helper service
	 * @param $log {Object} Logging service
	 * @return {Function} Enum instance
	 */
	function ShareStatusesEnum($log, BaseEnum, gettext) {

		$log = $log.getInstance('app.docs.ShareStatusesEnum');
		$log.debug('Initiated enum object');

		return new BaseEnum({
			PENDING: {
				label: gettext('Pending')
			},
			ACCEPTED: {
				label: gettext('Accepted')
			}
		});
	}

	module.service('ShareStatusesEnum', ShareStatusesEnum);
});

