// jshint unused: false
requirejs.config({
	paths: {
		'jquery-resize': 'assets/vendor/js/jquery-resize/jquery-resize',
		'angular-file-upload': 'assets/vendor/js/angular-file-upload/angular-file-upload',
		'angular-gridster': 'assets/vendor/js/angular-gridster/angular-gridster',
		'file-saver': 'assets/vendor/js/file-saver/file-saver'
	},

	shim: {
		'jquery-resize': ['jquery'],
		'angular-file-upload': {deps: ['angular']},
		'angular-gridster': ['angular', 'jquery-resize']
	}
});

define("apps/docs/require.conf", function(){});

