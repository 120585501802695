define('apps/docs/_models/Validators/DateValidator',['apps/docs/module'], function (module) {
	'use strict';

	/**
	 * Defines extend class of Validator holding date validator model initial properties
	 * @class DateValidator
	 * @mixes {app.docs.Validator}
	 * @memberOf app.docs
	 *
	 * @example
	 * {
	 *  validatorType: 'date'
	 *  min: '10-03-2015'
	 *  max: '21-03-2015'
	 * }
	 * @see {@link http://formvalidation.io/validators/date/}
	 * @param $log {Object} Logging service
	 * @param restmod {Object} Object Relational Mapper interface
	 * @return {*|Model} Model instance
	 */
	function DateValidator($log, restmod) {

		$log = $log.getInstance('app.docs.DateValidator');
		$log.debug('Created new instance');

		return restmod
			.model()
			.mix('Validator', {
				$templateUrl: {
					init: 'apps/docs/_directives/docsValidator/validators/date.html'
				},
				format: 'YYYY-MM-DD\'T\'hh:mm:ss.SSS\'Z\''
			});
	}

	module.factory('DateValidator', DateValidator);
});

