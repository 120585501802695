define('apps/docs/documents/_includes',[
	// Documents owned
	'./owned/module',
	'./owned/_includes',

	// Documents shared
	'./shared/module',
	'./shared/_includes',

	// Directives
	'./_directives/docsDocumentTemplateSelect/docsDocumentTemplateSelect'
], function () {
	'use strict';
});

