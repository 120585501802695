define('apps/docs/documents/owned/widgets/documentPreviewWidget/docsDocumentPreviewWidget',['apps/docs/documents/module'], function (module) {
	'use strict';

	/**
	 * Renders composite field editor that allows to create complex
	 * fields with custom validation.
	 * @class docsDocumentWidget
	 * @memberOf app.docs.templates.documents
	 *
	 * @param $stateParams {Object} Current request param provider
	 * @param $log {Object} Logging service
	 * @param DocumentAPI {Object} API interface for server communication
	 * @return {{restrict: string, templateUrl: string, controllerAs: string, controller: Function}}
	 */
	function docsDocumentPreviewWidget($stateParams, $log, DocumentAPI) {

		return {
			restrict: 'EA',
			templateUrl: 'apps/docs/documents/owned/widgets/documentPreviewWidget/docsDocumentPreviewWidget.html',
			controllerAs: 'vm',

			controller: function () {
				var vm = this;

				// variables
				vm.document = undefined;

				// functions
				vm.init = init;

				init();

				/**
				 * Initializes controller on set-up
				 * @method init
				 */
				function init() {
						if ($stateParams.version) {
							DocumentAPI
								.get($stateParams.id, {version: $stateParams.version})
								.then(function (model) {
									model.versions.$fetch();
									vm.document = model;
								});
						} else {
							DocumentAPI
								.get($stateParams.id)
								.then(function (model) {
									model.versions.$fetch();
									vm.document = model;
								});
						}

					$log.debug('Initiated preview controller');
				}
			}
		};
	}

	module.directive('docsDocumentPreviewWidget', docsDocumentPreviewWidget);
});

