define('apps/docs/documents/shared/factories/docsSharedTableConfigFactory',['apps/docs/documents/shared/module'], function (module) {
	'use strict';

	function docsSharedTableConfigFactory() {
		return {
			waitingTable: undefined
		};
	}

	module.factory('docsSharedTableConfigFactory', docsSharedTableConfigFactory);

});

